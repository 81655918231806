/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

// import { getOneOf } from '../../lib/utils';
import Tag from '../styles/Tag';

const Div = ({ as, ...rest }) => {
  if(as) {
    const Component = as;
    return <Component {...rest} />;
  }
  return <div {...rest} />;
};

const DayTags = styled(({className, days }) => {
  return (
    <div className={className} >
      {
        ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
          .map(day => {
            return (
              <Tag key={day} className={cx('tag text-small tag-info', { tagged: days.includes(day) })}>{day}</Tag>
            )
          })
      }
    </div>
  )
})`
`;

export const ResInfoCard1 = props => {
  const { cat } = props;
  const contacts = _.filter([
    {
      text: _.compact([
        !!_.get(cat, 'City') && `${_.get(cat, 'City')},`,
        _.get(cat, 'State'),
        _.get(cat, 'Zip_Code'),
      ]).join(' '),
    },
  ], ({ text }) => !!text );
  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Column className="header" mobile={16} tablet={16} computer={6}>
          <div className="tags">
            {_.castArray(_.get(cat, 'Type_of_Service', [])).map(tag => {
              return (<div className="tag tos text-small" key={tag}>{tag}</div>)
            })}
          </div>
          <div className="title text-h1">
            {_.get(cat, 'Name_of_Service', 'Name_of_Service')}
          </div>
          {
            !!contacts.length && 
            <div className="contacts">
              {
                _.map(contacts, item => {
                  const { text, ...rest } = item;
                  if(text) {
                    return (
                      <Div className="sub-title-2 text-p" key={text} {...rest}>
                        {text}
                      </Div>
                    );
                  }
                  return null;
                })
              }
            </div>
          }
          <Tag
            className={cx("tag text-small tag-info", { tagged: _.get(cat, 'in_Service_') === 'true'})}
          >
            { _.get(cat, 'in_Service_') === 'true' ? 'IN SERVICE' : 'NOT IN SERVICE' }
          </Tag>
        </Grid.Column>
        <Grid.Column className="details" mobile={16} tablet={16} computer={10}>
          {
            _.get(cat, 'Description_of_Service') && 
            <div className="desc-container">
              <div className="label text-p">Description</div>
              <div className="desc text-p">
                <ReactMarkdown source={_.get(cat, 'Description_of_Service', 'Description_of_Service')} />
              </div>
            </div>
          }
          {
            <div className="desc-container">
              <div className="label text-p">Days Open</div>
              <div className="desc">
                {
                  _.get(cat, 'Days_Open') ?
                    <DayTags days={_.castArray(_.get(cat, 'Days_Open'))} />:
                    <div className="text-p">N/A</div>
                }
              </div>
            </div>
          }
          {
            <div className="desc-container">
              <div className="label text-p">Hours</div>
              <div className="desc text-p">
                <ReactMarkdown source={_.get(cat, 'Hours') || 'N/A'} />
              </div>
            </div>
          }
        </Grid.Column>
      </Grid>
      {/* Data for tracking */}
      <div className="trackingData">
        <div className="location">{cat.Area}</div>
        <div className="category">{cat.table}</div>
      </div>
    </div>
  );
};

export default styled(ResInfoCard1)`
  border-radius: 20px;
  background: rgba(250, 236, 209, 0.45);
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      color: ${props => props.theme.primaryWhite};
      background: ${props => props.theme.primaryBlack};
      border: 1px solid ${props => props.theme.primaryBlack} !important;
      border-radius: 20px !important;
      padding: 2px 20px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .eligibility {
      @media (max-width: ${({ theme }) => theme.notDesktop}) {
        display: none;
      }  
    }
    .title {
      font-weight: 700;
      word-wrap: break-word;
    }
    .sub-title {
      font-size: 16px;
      font-weight: 500;
    }
    .contacts {
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
      }
      margin-bottom: 20px;
      font-weight: normal;
      .sub-title-2 {
        display: inline-block;
        font-weight: 500;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            color: ${props => props.theme.txtLight};
          }
        }
      }  
    }
    .tags {
      .tag {
        display: inline-block;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
          }
        }
      }
    }
    .tag-info {
      cursor: initial !important;
    }
    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .desc {
      }
      margin-bottom: 10px;
    }
  }
  height: 100%;
  &.type-1 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtPink};
    }
  }
`;
