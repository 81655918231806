/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';

// import { getOneOf } from '../../lib/utils';

export const ResInfoCard3 = props => {
  const { cat } = props;
  
  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Column className="details" mobile={16} tablet={16} computer={16}>
          <div className="title text-h4">
            Eligibility Info
          </div>
          {
            _.get(cat, 'Eligibility') && 
            <div className="desc-container">
              <div className="label text-p">Requirements</div>
              <div className="desc text-p">
                <ReactMarkdown source={_.get(cat, 'Eligibility')} />
              </div>
            </div>
          }
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default styled(ResInfoCard3)`
  border-radius: 20px;
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      color: inherit;
      background: transparent;
      border: 1px solid ${props => props.theme.primaryBlack} !important;
      border-radius: 20px !important;
      padding: 2px 20px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .eligibility {
      @media (max-width: ${({ theme }) => theme.notDesktop}) {
        display: none;
      }  
    }
    .title {
      font-weight: 700;
    }
    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .desc {
      }
      margin-bottom: 20px;
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.primaryGrey};
        padding-bottom: 20px;
      }
    }
  }
  height: 100%;

  &.type-1 {
    border: 1px solid ${props => props.theme.txtOrange};
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    border: 1px solid ${props => props.theme.txtTeal};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    border: 1px solid ${props => props.theme.txtYellow};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    border: 1px solid ${props => props.theme.txtPink};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtPink};
    }
  }  
`;
