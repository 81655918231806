import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import ResourceDetailPageTemplate from '../components/ResourceDetailPageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';
import '../graphql/newsFragment';

const ResourceDetailPage = ({ data, pageContext}) => {
  // const { markdownRemark: post } = data
  // indexing airtable data for filtering purpose
  const metaPost = useMetaOverride('meta', data.meta);

  const node = _.get(data, 'airtable', {});
  const { data: nodeData, ...rest } = node;
  const post = {
    ...nodeData,
    ...rest,
    catMeta: _.find(_.get(metaPost, 'frontmatter.categories', []), { name: rest.table }),
  };
  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <ResourceDetailPageTemplate
        contentComponent={HTMLContent}
        title={_.get(post, 'frontmatter.title', '')}
        content={_.get(post, 'html')}
        post={post}
      />
    </HomeLayout>
  )
}

ResourceDetailPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const resourcePageQuery = graphql`
  query ResourceDetailPage($id: String!) {
    meta: markdownRemark(fields: { slug: { eq: "/meta/"} }) {
      html
      frontmatter {
        title
        categories {
          name
          title
          description
          className
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
    airtable(id: { eq: $id } ) {
      ...resourceFragment
    }    
  }
`

export default ResourceDetailPage;
