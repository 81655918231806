import styled from 'styled-components';

const Tag = styled.div`
  display: inline-block;
  padding:  4px;
  min-width: 80px;
  text-align: center;
  background: ${props => props.theme.primaryWhite};
  margin: 4px 8px 4px 0;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-right: 4px;
  }
  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin-right: 4px;
  }
  border-radius: 20px;
  border: 1px solid ${props => props.theme.primaryTeal};
  color: ${props => props.theme.primaryTeal};
  &.tagged {
    background: ${props => props.theme.primaryTeal};
    color: white;
  }
  cursor: pointer;
  &.type-1 {
    border: 1px solid ${props => props.theme.txtOrange};
    color: ${props => props.theme.txtOrange};
    &.tagged {
      background: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    border: 1px solid ${props => props.theme.txtTeal};
    color: ${props => props.theme.primaryTeal};
    &.tagged {
      background: ${props => props.theme.bg1};
    }
  }
  &.type-3 {
    border: 1px solid ${props => props.theme.txtYellow};
    color: ${props => props.theme.txtYellow};
    &.tagged {
      background: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    border: 1px solid ${props => props.theme.txtPink};
    color: ${props => props.theme.txtPink};
    &.tagged {
      background: ${props => props.theme.txtPink};
    }
  }  
`
export default Tag;