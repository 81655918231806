/* eslint-disable react/require-default-props */

/**
 * @parentComponent: ResourceDetailPageTemplate.js -- template for the Individual Resource Page
 * @alternativeName: NextStepsCard
 * @notes: this component replaces ResInfoCard4. it's been updated to use hooks and add toggle functionality to 'Next Steps'
 */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';

import ResInfoCard1 from './ResInfoCard1';
import ResInfoCard2 from './ResInfoCard2';
import ResInfoCard3 from './ResInfoCard3';
import ResInfoCard4 from './ResInfoCard5';

export const CatList4 = props => {
  const { cat } = props;
  return (
    <div className={cx(props.className)}>
      <Grid >
        <Grid.Column mobile={16} tablet={16} computer={16} className="col grid">
          <ResInfoCard1 cat={cat} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={6} className="col grid">
          <ResInfoCard2 cat={cat} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={10} className="col grid">
          <Grid.Row className="info3">
            <ResInfoCard3 cat={cat} />
          </Grid.Row>
          <Grid.Row className="info4">
            <ResInfoCard4 cat={cat} />
          </Grid.Row>
        </Grid.Column>
      </Grid>

    </div>
  )
};

export default styled(CatList4)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  .info3 {
    margin-bottom: 20px;
  }
  > .title {
    font-size: 30px;
    line-height: 36px;
    color: ${props => props.theme.primaryPink};
    padding: 30px 10px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      display: none;
    }  
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;
