/* eslint-disable react/require-default-props */

/**
 * @parentComponent: CatList4.js -- Individual Resource Page
 * @alternativeName: NextStepsCard
 * @notes: this component replaces ResInfoCard4. it's been updated to use hooks and add toggle functionality to 'Next Steps'
 */
import React, {useState} from 'react';
import styled from 'styled-components';
import { Grid, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { HTMLContent } from '../Content'
// import { getOneOf } from '../../lib/utils';


export const ResInfoCard5 = props => {
  const { cat } = props;
  const Next_Steps = _.get(cat, 'Next_Steps');


  const [isInterested, setInterest] = useState(true);

  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Row className="details" onClick={ () => setInterest(!isInterested) }>
          <Grid.Column mobile={12} tablet={14} computer={14}>
            <div className="title text-h4" >
                Next Steps
            </div>
          </Grid.Column>
          <Grid.Column mobile={4} tablet={2} computer={2}>
              <Icon name='angle down' className='resource-arrow' {...(isInterested ? {flipped: 'vertically'} : {}) } style={{fontSize: '30px', lineHeight: '31px' }} />
            </Grid.Column>
        </Grid.Row>

          <div className={isInterested ? 'not-interested' : 'interested'}>
            {
              !!Next_Steps ?
                <HTMLContent post={{ rawMarkdownBody: Next_Steps }} /> :
                <div>
                  {
                    <div className="desc-container">
                      <div className="label text-p">Please contact the organization to determine next steps</div>
                    </div>
                  }
                </div>
              }
          </div>
      </Grid>
    </div>
  );
};

export default styled(ResInfoCard5)`
  border: 1px solid #707070;
  border-radius: 20px;
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .header {
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .title {
      font-weight: 700;
      margin-bottom: 0px !important;
    }
    .not-interested {
      display: none !important;
    }

    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
        margin-top: 10px !important;
      }
      .desc {
      }
      margin-bottom: 20px;
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.primaryGrey};
        padding-bottom: 20px;
      }
    }
    
  height: 100%;
  }
  &.type-1 {
    border: 1px solid ${props => props.theme.txtOrange};
    .content {
      
    }
    .title, .tos, .sub-title-2, .resource-arrow {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    border: 1px solid ${props => props.theme.txtTeal};
    .content {
      
    }
    .title, .tos, .resource-arrow {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    border: 1px solid ${props => props.theme.txtYellow};
    .content {
      
    }
    .title, .tos, .resource-arrow {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    border: 1px solid ${props => props.theme.txtPink};
    .content {
      
    }
    .title, .tos, .resource-arrow {
      color: ${props => props.theme.txtPink};
    }
  }  
`;
