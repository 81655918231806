/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

// import { getOneOf } from '../../lib/utils';

const Div = ({ as, ...rest }) => {
  if(as) {
    const Component = as;
    return <Component {...rest} />;
  }
  return <div {...rest} />;
};

export const ResInfoCard2 = props => {
  const { cat } = props;

  const contacts = [
    {
      text: _.get(cat, 'Website') && 'Click to View',
      label: 'Website',
      as: 'a',
      href: _.get(cat, 'Website', ''),
    },
    {
      text: _.get(cat, 'Email'),
      label: 'Email',
      as: 'a',
      href: `mailto:${_.get(cat, 'Email', '')}`,
    },
    {
      label: 'Phone Number',
      text: _.get(cat, 'Phone_Number'),
    },
    {
      label: 'Address',
      text: _.get(cat, 'Address'),
      className: 'address'
    },
    {
      text: _.compact([
        !!_.get(cat, 'City') && `${_.get(cat, 'City')},`,
        _.get(cat, 'State'),
        _.get(cat, 'Zip_Code'),
      ]).join(' '),
    },
  ]
  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Column className="header" mobile={16} tablet={16} computer={16}>
          <div className="title text-h4">
            Organization Overview
          </div>
          <div className="sub-title text-h6">
            {_.get(cat, 'Organization_Name', 'Organization_Name')}
          </div>
          <div className="contacts">
            {
              _.map(contacts, item => {
                const { label, text, className, ...rest } = item;
                if(text) {
                  return (
                    <div className={cx("contact", className)} key={text} >
                      <div className="label text-p">{label}</div>
                      <Div className="value text-p" key={text} {...rest}>
                        {text}
                      </Div>
                    </div>
                  );
                }
                return null;
              })
            }
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default styled(ResInfoCard2)`
  border-radius: 20px;
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      color: inherit;
      background: transparent;
      border: 1px solid ${props => props.theme.primaryBlack} !important;
      border-radius: 20px !important;
      padding: 2px 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .eligibility {
      @media (max-width: ${({ theme }) => theme.notDesktop}) {
        display: none;
      }  
    }
    .title {
      font-weight: 700;
    }
    .sub-title {
    }
    .contacts {
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
      }
      .contact {
        margin-bottom: 20px;
        .value {
          color: ${props => props.theme.txtLight};
        }  
        .label {
          font-weight: 700;
          margin-bottom: 0;
        }
        &.address {
          margin-bottom: 0px;
        }
      }
    }
    .tags {
      .tag {
        color: ${props => props.theme.txtLight};
        display: inline-block;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            color: ${props => props.theme.txtLight};
          }
        }
      }
    }
    .desc-container {
      color: ${props => props.theme.txtLight};
      .label {
        font-weight: 600;
        margin-bottom: 0;
      }
      .desc {
      }
      margin-bottom: 10px;
    }
  }
  height: 100%;

  &.type-1 {
    border: 1px solid ${props => props.theme.txtOrange};
    .content {
    }
    .title, .tos, .sub-title-2 {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    border: 1px solid ${props => props.theme.txtTeal};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    border: 1px solid ${props => props.theme.txtYellow};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    border: 1px solid ${props => props.theme.txtPink};
    .content {
    }
    .title, .tos {
      color: ${props => props.theme.txtPink};
    }
  }
`;
